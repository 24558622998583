import { Injectable, inject, Signal, WritableSignal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Auth, GoogleAuthProvider, User, UserCredential, idToken, signInWithRedirect, signInWithPopup } from '@angular/fire/auth';
import { environment } from '../../environments/environment';

export const firebaseRedirectAuthWrapper = {
  signInWithRedirect,
};

export const firebasePopupAuthWrapper = {
  signInWithPopup,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly auth: Auth = inject(Auth);
  private readonly googleAuthProvider: GoogleAuthProvider = inject(GoogleAuthProvider);
  readonly token: Signal<string | null> = toSignal(idToken(this.auth), { initialValue: null });
  readonly user: WritableSignal<User | null> = signal(null);

  constructor() {
    this.auth.onAuthStateChanged(user => this.user.set(user));
  }

  authenticateWithGoogle(): Promise<UserCredential> {
    return environment.authMode === 'redirect'
      ? firebaseRedirectAuthWrapper.signInWithRedirect(this.auth, this.googleAuthProvider)
      : firebasePopupAuthWrapper.signInWithPopup(this.auth, this.googleAuthProvider);
  }

  logout(): Promise<void> {
    return this.auth.signOut();
  }
}
